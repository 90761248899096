import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Editor from 'react-simple-wysiwyg';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment"




import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest } from '../redux/actionsAPI';
import { dynamicSortMultiple, oValues, valueLabel, firstValue } from './_tools';


class NewsletterArticleEditModal extends Component {
    textFields = [['titleRubrique', "Section"], ['title', "Title"], ['subTitle',"Subtitle"], ['content', 'Content']]
    state =  Object.assign({lang:{value:-1, label:'-'}, date: null, dateT: null, isInternal:true }, ...this.textFields.map ((el) => ({ [el[0]]:"" }))) 
    
    componentDidMount() {
        var cs = {}
        cs["lang"] = firstValue(this.props.newslettersConfig.languages)
        if (this.props.interface.editingNewsletterArticleId in this.props.newslettersConfig.articles)
        {
            var el = this.props.newslettersConfig.articles[this.props.interface.editingNewsletterArticleId]
            const ds = el.date ? moment (el.date, 'DD-MM-YYYY').toDate() : null
            cs['date'] = ds
            cs['dateT'] = ds ? ds.getTime():ds
            cs['lang'] = valueLabel (this.props.newslettersConfig.languages[el.langId], "id", "lang")
            
            for (var tf of this.textFields)
                cs[tf[0]] = el[tf[0]]
            cs['isInternal'] = el.isInternal
        }
        this.setState (cs)
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };
    
    onHTMLChange = e => {
        this.setState({ content: e.target.value });
    }

    render () {
        var that = this
        
        return (
            <Modal size='xl'  show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingNewsletterArticleId", "paramValue": null})}}>
                <Modal.Header closeButton className='mb-3'>
                    <Modal.Title>{(that.props.interface.editingNewsletterArticleId > 0) ? "Edit article":"Create article"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="post" onSubmit={(e)=>{e.preventDefault}} className='mb-3'>
                        <div className="mb-3">
                            <label htmlFor="date" className="form-label">Date</label>
                            <DatePicker 
                                selected={that.state.date} 
                                dateFormat="dd-MM-yyyy"
                                calendarStartDay={1}
                                onChange={(date) => { that.setState ({"date":date, "dateT": date.getTime()}); }}/>
                            <Select 
                                    className='mt-3'
                                    menuPosition="fixed"
                                    name="lang"
                                    onChange={that.handleSelectChange}
                                    value={that.state["lang"]}
                                    options={oValues(this.props.newslettersConfig.languages).sort (dynamicSortMultiple("*lang")).map (function (cp, ic) { return {label:cp.lang, value:cp.id} })}
                                />
                                
                            {this.textFields.map (function (fn, ic) {
                                return <div className="control mb-3" key={"tf" + ic}>
                                <label htmlFor={fn[0]} className="form-label">{fn[1]}</label>
                                
                                {(fn[0] === 'content') ? 
                                    <Row>
                                        <Col md={6}>
                                            <Editor value={that.state[fn[0]]} onChange={that.onHTMLChange} />
                                        </Col>
                                        <Col md={6}>
                                            <textarea
                                                id={fn[0]}
                                                className="form-control"
                                                name={fn[0]}
                                                onChange={that.handleInputChange}
                                                value={that.state[fn[0]]}
                                                rows={10}
                                            />
                                        </Col>
                                    </Row>
                                    :
                                    <input
                                        id={fn[0]}
                                        className="form-control"
                                        name={fn[0]}
                                        onChange={that.handleInputChange}
                                        value={that.state[fn[0]]}
                                        type="text"
                                    />}
                            </div>
                            })}
                        </div>    
                    </form>    
                </Modal.Body>
                <Modal.Footer className='pb-3 pt-3 mb-3 mt-3'>
                <Button variant="secondary" className='mt-3' onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingNewsletterArticleId", "paramValue": null})}}>
                    Close
                </Button>
                <Button variant="primary" className='mt-3' disabled={!that.state.isInternal} onClick={(e)=>{ this.props.dispatch (apiRequest({'endpoint': 'newsletterArticleEdit', "articleId": that.props.interface.editingNewsletterArticleId, "newState":that.state}, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingNewsletterArticleId", "paramValue": null})) }}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        newslettersConfig: state.data.newslettersConfig,
    }
}

export default connect(mapStateToProps)(NewsletterArticleEditModal)